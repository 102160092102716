<template>
  <section>
    <!--列表-->
    <div class="block">
      <el-table class="tbl" :data="termList" highlight-current-row>
        <el-table-column prop="termId" label="termId"> </el-table-column>
        <el-table-column prop="termName" label="termName"> </el-table-column>
        <el-table-column prop="weight" sortable label="weight">
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onEditTerm(scope.row.termId)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="onDeleteTerm(scope.row.termId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="toolbar">
      <el-row>
        <el-col :span="8">
          <el-input v-model="termId" placeholder="根据termId query"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="onQueryCourse(termId)"
            >查询</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="block">
      <el-table class="tbl" :data="termCourseList" highlight-current-row>
        <el-table-column prop="termId" label="termId"> </el-table-column>
        <el-table-column prop="courseId" label="courseId"> </el-table-column>
        <el-table-column prop="courseName" label="courseName">
        </el-table-column>
        <el-table-column prop="weight" sortable label="weight">
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="onEditTermCourse(scope.row.termId, scope.row.courseId)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="onDeleteTermCourse(scope.row.termId, scope.row.courseId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import { termApi } from "@/api/termApi";
import { alertMessage } from "@/util/alertMessageUtil.js";
export default {
  data() {
    return {
      termId: "",
      termList: [],
      termCourseList: []
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    //获取列表
    onQuery() {
      termApi
        .listTerm(1) // pageNo
        .then(ret => {
          if (ret.code == 0) {
            this.termList = ret.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onQueryCourse(termId) {
      termApi
        .listTermCourse(termId)
        .then(ret => {
          if (ret.code == 0) {
            this.termCourseList = ret.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onEditTerm(termId) {
      this.$router.push({
        path: "term-add",
        query: { termId: termId }
      });
    },
    onDeleteTerm(termId) {
      let param = { termId: termId };
      termApi
        .deleteTerm(param)
        .then(ret => {
          if (ret.code == 0 && ret.data) {
            alertMessage("删除成功", "success");
            this.onQuery();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onEditTermCourse(termId, courseId) {
      this.$router.push({
        path: "term-course-add",
        query: { termId: termId, courseId: courseId }
      });
    },
    onDeleteTermCourse(termId, courseId) {
      let param = { termId: termId, courseId: courseId };
      termApi
        .deleteTermCourse(param)
        .then(ret => {
          if (ret.code == 0 && ret.data) {
            alertMessage("删除成功", "success");
            this.onQueryCourse(this.termId);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.block {
  width: 99%;
  .tbl {
    min-width: 800px;
  }
}
.toolbar {
  margin: 20px;
}
</style>
