import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const getTerm = id => {
  return ajax.get(`${base}/api/itedu/v1/termcategory/get?id=${id}`);
};

const addTerm = params => {
  return ajax.post(`${base}/api/itedu/v1/termcategory`, params);
};

const listTerm = pageNo => {
  return ajax.get(`${base}/api/itedu/v1/termcategory/query/${pageNo}`);
};

const deleteTerm = params => {
  return ajax.post(
    `${base}/api/itedu/v1/termcategory/delete`,
    Qs.stringify(params)
  );
};

const getTermCourse = (termId, courseId) => {
  return ajax.get(
    `${base}/api/itedu/v1/termcategory/course/get?termId=${termId}&courseId=${courseId}`
  );
};

const listTermCourse = termId => {
  return ajax.get(`${base}/api/itedu/v1/termcategory/course/${termId}`);
};

const addTermCourse = params => {
  return ajax.post(`${base}/api/itedu/v1/termcategory/course/`, params);
};

const deleteTermCourse = params => {
  return ajax.post(
    `${base}/api/itedu/v1/termcategory/course/delete`,
    Qs.stringify(params)
  );
};

export const termApi = {
  getTerm: getTerm,
  addTerm: addTerm,
  listTerm: listTerm,
  deleteTerm: deleteTerm,
  getTermCourse: getTermCourse,
  listTermCourse: listTermCourse,
  addTermCourse: addTermCourse,
  deleteTermCourse: deleteTermCourse
};
